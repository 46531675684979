.no-response
{
    width:370px;
    margin: 10px;
    font-family: Montserrat, sans-serif;
    background-color: #ededed;
    border: none;
    font-size: 16px;
    font-weight: 700;
    padding: 12px 60px;
    cursor: pointer;} 