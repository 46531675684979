.access-code-subheading {
  text-align: center;
  margin-top: 5px;
}

.access-code-subheading-text {
  text-align: center;
  color: #fff;
  line-height: 28px;
  margin-top: 40px;
  margin-bottom: 53px;
  font: 400 23px Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
  .access-code-subheading-text {
    max-width: 100%;
    margin-top: 20px;
    line-height: 20px;
  }
}

/* Add this media query for smaller screens */
@media (max-width: 767px) {
  .access-code-subheading-text {
    max-width: 100%;
    font-size: 18px; /* Adjust font size for smaller screens */
    margin-top: 15px; /* Adjust margin for smaller screens */
    margin-bottom: 30px; /* Adjust margin for smaller screens */
  }
}
