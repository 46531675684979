/* Default styling for larger screens */
.access-code-1 {
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 50px 60px;
}

.access-code-2 {
  display: flex;
  width: 100%;
  max-width: 1596px;
  flex-direction: column;
  align-items: center;
  margin: 12px 0 98px;
  text-align: center;
}

.otp-form {
  margin-top: 20px;
}

/* Media query for 1200px screens */
@media (max-width: 1200px) {
  .access-code-1 {
    padding: 40px 40px;
  }

  .access-code-2 {
    margin-bottom: 30px;
  }

  .otp-form {
    margin-top: 20px;
  }
}

/* Media query for 992px screens */
@media (max-width: 992px) {
  .access-code-1 {
    padding: 30px 30px;
  }

  .access-code-2 {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .otp-form {
    margin-top: 30px;
  }
}

/* Media query for 768px screens */
@media (max-width: 768px) {
  .access-code-1 {
    padding: 20px 20px;
  }

  .access-code-2 {
    margin-bottom: 20px;
  }

  .otp-form {
    margin-top: 20px;
  }
}

/* Media query for 600px screens */
@media (max-width: 600px) {
  .access-code-1 {
    padding: 15px 15px;
  }

  .access-code-2 {
    margin-bottom: 15px;
  }

  .otp-form {
    margin-top: 15px;
  }
}

/* Media query for 480px screens */
@media (max-width: 480px) {
  .access-code-1 {
    padding: 10px 10px;
  }

  .access-code-2 {
    margin-bottom: 10px;
  }

  .otp-form {
    margin-top: 10px;
  }
}
