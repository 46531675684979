/* Default styling for larger screens */
.access-code-heading {
  text-align: center;
}

.access-code-heading-text {
  text-align: center;
  color: #fff;
  font-weight: 700;
  font-size: 62px;
  line-height: 65px;
}

/* Media query for 991px screens and below */
@media (max-width: 991px) {
  .access-code-heading-text {
    max-width: 100%;
    font-size: 40px;
    line-height: 45px;
  }
}

/* Media query for 767px screens and below */
@media (max-width: 767px) {
  .access-code-heading-text {
    font-size: 30px; /* Adjust font size for smaller screens */
    line-height: 35px;
  }
}

/* Media query for 600px screens and below */
@media (max-width: 600px) {
  .access-code-heading-text {
    font-size: 24px; /* Adjust font size for the smallest screens */
  }
}

@media (max-width: 480px) {
  .access-code-heading-text {
    font-size: 14px; /* Adjust font size for the smallest screens */
    line-height: 25px;
  }
}
