.referral-code{
    color: white;
    font-size: 110px;
    font-weight: 700;
}

@media (max-width: 1200px) {
    .referral-code{
    font-size: 90px;
    }
  }
  
  /* Media query for 992px screens */
  @media (max-width: 992px) {
    .referral-code{
        font-size: 90px;
        }
  }
  
  /* Media query for 768px screens */
  @media (max-width: 768px) {
    .referral-code{
        font-size: 70px;
        }
  }
  
  /* Media query for 600px screens */
  @media (max-width: 600px) {
    
  }
  
  /* Media query for 480px screens */
  @media (max-width: 480px) {
    
  }
  