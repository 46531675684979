.ms_details_movie_year{
    margin-top: 18px;
    margin-bottom: 18px;
}

.ms_details_genre_container{

}


.ms_details_score_container{
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
}

.ms_score_text{
    color: black;
    font-size: 30px;
    margin-top: 8px;
}

.ms_details_score_title_container{
    width: 70%;
    height: 100%;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}



.ms_details_where_to_watch_heading{
    color: white;
    margin-left: 30px;
}

.ms_details_channels_left{
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: 50px;
}
.ms_details_channel_image{
    margin-left: 10px;
    width: 40%;
    height: 80%;
    object-fit: contain;
}

.ms_details_channel_title{
    margin-left: 25px;
    color: white;
}

.ms_details_channel_subtext{
    margin-left: 25px;
    font-weight: 600;
}
.ms_details_availability_text{
    margin-left: 25px;
    line-height: 20px;
    font-size: 14px;
}

.ms_details_reviews_container{
    display: flex;
    justify-content: space-between;
}
.ms_details_review_container{
    flex: 0 1 48%;
}
.ms_details_likes{
    color: white;
    margin-left: 10px;
}
.ms_details_good_img{
    width: 15%;
    height: 15%;
    border-radius: 10px;
}
.ms_details_bad_img{
    width: 15%;
    height: 15%;
    border-radius: 10px;
}

.ms_details_pro_list{
    margin-left: 10px;
}
.ms_details_cons_list{
    margin-left: 10px;
}
.ms_details_pro_desc{
    color: white;
    font-size: 14px;
    padding-right: 24px;
}

.ms_details_cons_desc{
    color: white;
    font-size: 14px;
    padding-right: 24px;
}


.ms_details_moviehighlight_container{
    height: 100px;
}
.ms_details_showhighlight_container{
    height: 100px;
}
.ms_details_moviehighlight_title{
    margin-left: 55px;
}
.ms_details_showhighlight_title{
    margin-left: 55px;
}



.ms_details_footer{
    border-top: 0.5px solid gray;
    background-color: #fff;
    margin-bottom: 60px;
}



@media only screen and (max-width:767.99px) {
    .imdb-content {
        text-align: center;
    }

    .imdb-content .ms_details_genre_container {
        justify-content: center;
    }

    .ms_details_score_container {
        flex-direction: column;
        height: auto;
        align-items: center;
    }

    .ms_details_score_title_container {
        width: 80%;
        margin-left: 0;
        margin-top: 0.5rem;
    }

    .ms_details_score_title_container h5 {
        margin-bottom: 0;
    }

    .ms_details_where_to_watch_heading {
        text-align: center;
        margin: 0;
    }

    .ms_details_channels_left {
        flex-direction: column;
    }

    .ms_details_channels_left .channels-1 {
        width: 100%;
    }

    .ms_details_channels_left .channels-1 .service {
        width: 100%;
        height: auto;
    }

    .ms_details_reviews_container {
        flex-direction: column;
        text-align: center;
    }

    .ms_details_review_container {
        width: 100% !important;
    }

    .ms_details_likes {
        margin-left: 0;
    }

    .ms_details_pro_list {
        height: auto !important;
    }

    .ms_details_cons_desc,
    .ms_details_pro_desc {
        padding-right: 0;
    }

    .ms_details_cons_list {
        height: auto;
    }

    .reviews-container {
        height: auto !important;
    }

    .ms_details_cons_list,
    .ms_details_pro_list {
        margin-left: 0;
        margin-bottom: 2.5rem;
    }

    .StyledContainer {
        text-align: center;
    }

    .StyledContainer .row {
        margin-left: calc(var(--bs-gutter-x)*-.5) !important;
    }

    .footer-logo.tex-left {
        text-align: center;
    }

    .footer-dev {
        text-align: center;
    }

    .footer-dev .footer-links {
        text-align: center !important;
        padding: 0;
    }

    .footer .row {
        flex-direction: column-reverse;
        margin-bottom: 15px;
    }

    .ms_details_footer {
        margin-bottom: 15px;
    }

    .ms_details_bad_img,
    .ms_details_good_img {
        width: 80px;
        height: 80px;
    }

    .ms_details_moviehighlight_container,
    .ms_details_showhighlight_container {
        height: 50px;
    }

    .ms_details_review_container {
        margin-bottom: 30px;
        margin-top: 15px;
    }

    /* 24-Jan */
    .imdb-content h1.text-white {
        margin-top: 1rem;
    }

    .imdb-content .row.mt-4 {
        margin-top: 3rem !important;
    }

    .ms_details_where_to_watch_heading {
        padding: 0 2rem 1rem;
    }

    .ms_details_channel_subtext {
        white-space: nowrap;
    }

    .footer-dev,
    .footer-logo.tex-left {
        text-align: center !important;
    }

    .ms-cust-sm-container {
        margin-top: 60px !important;
    }

    .ms-cust-sm-container-fluid {
        margin-bottom: 60px !important;
    }

}