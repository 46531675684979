.access-code-1 {
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 50px 60px;
}
@media (max-width: 991px) {
  .access-code-1 {
    max-width: 100%;
    padding: 0 20px;
  }
}
.access-code-2 {
  display: flex;
  width: 100%;
  max-width: 1596px;
  flex-direction: column;
  align-items: center;
  margin: 12px 0 238px;
  text-align: center;
}
@media (max-width: 991px) {
  .access-code-2 {
    max-width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
}
