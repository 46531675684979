@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
  overflow-x: hidden;
}
a {
  cursor: pointer;
}
:root {
  /* fonts */
  --font-montserrat: Montserrat;

  /* font sizes */
  --font-size-4xl: 1.438rem;
  --font-size-lg: 1.125rem;
  --font-size-42xl-8: 3.863rem;
  --font-size-18xl: 2.313rem;
  --font-size-30xl: 3.063rem;
  --font-size-lg-6: 1.163rem;
  --font-size-104xl-9: 7.744rem;
  --font-size-12xl: 1.938rem;
  --font-size-31xl: 3.125rem;
  --font-size-2xl-2: 1.325rem;
  --font-size-29xl-6: 3.038rem;

  /* Colors */
  --color-white: #fff;
  --color-whitesmoke: #ededed;

  /* Gaps */
  --gap-137xl: 9.75rem;
  --gap-8xl: 1.688rem;
  --gap-mid: 1.063rem;
  --gap-0: 0rem;
  --gap-xl: 1.25rem;

  /* Paddings */
  --padding-xl: 1.25rem;
  --padding-15xl: 2.125rem;
  --padding-9xs: 0.25rem;
  --padding-49xl: 4.25rem;
  --padding-23xl: 2.625rem;
  --padding-8xs: 0.313rem;
}
.input-trueflix:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px transparent inset !important; /* Change 'white' to any color you desire */
}

.container-fuild.bg-drak {
  background-color: black;
}

/* .main-price-container {
  height: 100vh;
} */

.subs-btn-content {
  margin-bottom: 4rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

@media only screen and (min-width: 1366px) and (max-width: 1440px) {
  /* Your CSS styles for this range */
  .movies-slider-container {
    height: 505px !important;
    margin-top: 7rem;
    width: 100%;
  }

  p {
    font-size: 16.75px !important;
  }
}



@media only screen and (min-width: 1200px) and (max-width: 2000px) {
  .form-group.justify-content-flex {
    display: flex;
    background-color: #333;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    height: 64.32px !important;
    width: 80% !important;
  }
}



@media only screen and (min-width: 1440px) and (max-width: 1920px) {
  .movies-slider-container {
    height: 505px !important;
    margin-top: 7rem;
    width: 100%;
    /* background-color: #fff; */
  }
}

h2.text-white.log-name {
  font-weight: 700;
  font-size: 35.51px;
  padding-left: 20px !important;
}

a.nav-link {
  color: white;
  font-family: "Montserrat";
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  font-family: "Montserrat", sans-serif;

  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  background-color: #000;
  overflow-x: hidden;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
  font-family: "Montserrat";
}

.nav-link:hover,
.nav-link:focus {
  color: rgb(255 255 255 / 80%);
}

.suscribe-btn {
  height: 32px;
  width: 25%;
  font-size: 14px;
  font-weight: 700;
  font-family: "Montserrat";
  border-radius: 0px;
}

.month-tag {
  font-size: 13px;
  font-weight: 700;
}

.month-tag {
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  float: right;
  /* left: 0; */
  font-family: "Montserrat";
  position: absolute;
  padding-top: 20px;
  padding-left: 19px;
}

.price-heading {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 60px;
}
.subscribe-heading{
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 20px;
  color: #28a745;
}

p {
  margin-top: 0;
  /* margin-bottom: 1rem; */
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
}
.suscribe-btn a {
  text-decoration: none;
}

/* neww csss */

.showcase-container {
  position: relative;
  padding: 0 !important;
}

.showcase-container .row {
  background-image: url("https://cdn.builder.io/api/v1/image/assets/TEMP/7c0f0c2dc82d96819aa98217862d3d01e0472d53b3d911486af77dbda0e4d164?apiKey=6a852500c0b84807b2e327b6acaeda86&");
  background-size: cover;
  background-position: center;
}

.text-container {
  margin-right: 176px;
}

@media (max-width: 991px) {
  ul.footer-links {
    list-style: none !important;
    text-align: justify !important;
    /* padding: 0px !important; */
  }
  .form-group.justify-content-flex {
    display: flex;
    background-color: #333;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    height: 64.32px !important;
    width: 100% !important;
}

  .text-container {
    margin-right: 10px;
  }
  .ytp-large-play-button {
    position: absolute;
    left: 26% !important;
    top: 50% !important;
    width: 68px !important;
    height: 48px !important;
    margin-left: -34px;
    margin-top: -24px;
    -webkit-transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
    transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
    z-index: 63;
  }
  .close-btn .MuiSvgIcon-root {
    font-size: 8px;
    position: absolute;
    left: -41px !important;
    right: 0px !important;
    top: -2px !important;
    z-index: 12;
    background-color: black;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding: 15px;
  }

  .imdb-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .platform-name {
    padding-left: 22px;
    text-align: left;
  }
  .movies-icons {
    display: flex;
    justify-content: space-around;
    align-content: center;
    flex-direction: row;
    align-items: flex-start;
    margin-left: 0px !important;
  }
  .movies-slider-container {
    height: 334px !important;
    margin-top: 2rem;
  }
  .card-yt {
    background-color: black;
    /* margin-top: 1rem; */
    padding-bottom: 35px;
  }

  .red {
    background-color: black;
    border: 6px solid #ff3a5f;
    width: 112.62px;
    height: 112.62px;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #ff3a5f;
    font-size: 28px;
    font-weight: 700;
    margin: auto;
  }

  .green {
    background-color: black;
    border: 6px solid green;
    width: 112.62px;
    height: 112.62px;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    color: green;
    font-size: 28px;
    font-weight: 700;
    margin: auto;
  }

  .yellow {
    background-color: black;
    border: 6px solid #fff95e;
    width: 112.62px;
    height: 112.62px;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #fff95e;
    font-size: 28px;
    font-weight: 700;
    margin: auto;
  }
}

.title {
  font-size: 115px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  padding-top: 37px;
  text-align: left;
}
.profileName {
  font-size: 115px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  /* padding-top: 37px; */
  text-align: left;
}

@media (max-width: 991px) {
  .title {
    font-size: 40px !important;
    padding-top: 20px;
  }
  p.btn.deactivate-p.text-white {
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;
    /* position: absolute; */
    margin-top: 0rem !important;
  }
  button.btn.pass-btn {
    background-color: #ededed;
    color: black;
    width: 70%;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    border-radius: 0px;
    height: 26px;
    padding: 0px;
    margin-top: 15px !important;
  }
  .col-lg-2.email {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 9px solid #1a1a1a;
    text-transform: uppercase;
    color: white;
    height: 100%;
    width: 160px !important;
    padding-right: 0px !important;

    margin-left: 7rem;
    text-transform: uppercase;
  }
  .col-lg-2.name {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 9px solid #1a1a1a;
    text-transform: uppercase;
    color: white;
    height: 100%;
    width: 160px !important;
    padding-right: 0px !important;

    margin-left: 7rem;
    text-transform: uppercase;
  }
  .col-lg-2.password {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 9px solid #1a1a1a;
    text-transform: uppercase;
    color: white;
    height: 100%;
    width: 160px !important;
    padding-right: 0px !important;

    margin-left: 7rem;
    text-transform: uppercase;
  }
  .col-lg-2.wanumber {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 9px solid #1a1a1a;
    text-transform: uppercase;
    color: white;
    height: 100%;
    width: 160px !important;
    padding-right: 0px !important;

    margin-left: 7rem;
    text-transform: uppercase;
  }
  @media (max-width: 426px) {
    .col-lg-2.email {
      margin-left: 0rem !important;
    }
    .col-lg-2.name {
      margin-left: 0rem !important;
    }

    .col-lg-2.password {
      margin-left: 0rem !important;
      text-align: left;
    }
    .col-lg-2.wanumber {
      margin-left: 0rem !important;
      text-align: left;
    }
    .col-lg-2.password p {
      text-align: left;
    }
    p.name {
      padding-right: 4rem;
    }
    p.email {
      padding-right: 3rem;
    }
    p.login-password {
      padding-right: 12px;
    }
  }
  @media (max-width: 376px) {
    p.name {
      padding-right: 3rem;
    }
  }

  label.form-check-label.text-white {
    font-size: 22px !important;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    margin-top: 1rem !important;
    margin-left: -24px !important;
  }

  .trueflix-nav {
    padding: 0px !important;
  }

  .suscribe-btn {
    height: 50px;
    width: 70%;
    font-size: 14px;
    font-weight: 700;
    font-family: "Montserrat";
    border-radius: 0px;
  }
}

.subtitle {
  display: flex;
  flex-direction: column;
  margin-top: -36px;
  padding-left: 26px;
}

@media (max-width: 991px) {
  .subtitle {
    padding-left: 20px;
  }
  .custom-dialog-content iframe {
    width: 100%;
  }
  button.account-submit.sign-up {
    border: none;
    height: 52px;
    padding: 0px;
    margin: 0px;
    font-size: 18px;
    width: 100%;
  }
  /* .header h1 {
  color: #fff;
  font: 700 2em/1 Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
  text-align: center;
  padding: 2em;
  font-size: 40px;
  font-weight: 700;
  line-height: 30px!important;
  text-align: left;
} */
  .event-title {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    margin-top: 10px;
    text-align: center;
    padding-bottom: 35px;
  }
  p.email {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    padding-left: 17px !important;
  }

  .search-btn-movies {
    padding: 6px 31px !important;
    background-color: #333333 !important;
    border: none !important;
    border-radius: 0px !important;
    margin-left: 0rem !important;
  }
  .movie-card {
    background-color: black;
    width: 25rem !important;
    margin: auto;
  }

  .movie-card img {
    width: 100%;
    height: auto !important;
  }
}

.subtitle-text {
  font-family: "Montserrat", sans-serif;
}

.subtitle-text.first {
  font-weight: 700;
  font-size: 115px;
}

@media (max-width: 991px) {
  .subtitle-text.first {
    font-size: 40px;
  }
}

.subtitle-text:not(:first-child) {
  margin-top: 54px;
}

@media (max-width: 991px) {
  .subtitle-text:not(:first-child) {
    margin-top: 40px;
  }
  .movie-card {
    background-color: black;
    width: auto !important;
  }
  h2.mb-5.text-white.signup-heading {
    font-size: 40px !important;
  }

  .accounts-card .card-footer {
    padding: 0px;
    position: static !important;
    top: 262px;
    width: 100%;
  }
}

div#root {
  background-color: black;
}
.movie-card-body {
  background-color: black;
}

.movie-card {
  background-color: black;
}

.movie-card img {
  width: 100%;
  height: 300px;
}

.movie-card {
  background-color: black;

  width: 13rem;
  text-align: center;
  margin: auto;
}
.movie-card h5 {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 17.04px;
}

.movie-card p {
  line-height: 0.04px;
}
.movie-card-body {
  background-color: black;
  padding: 0px;
  padding-top: 16px;
}

.movies-field {
  color: #666666 !important;

  background-color: #333333 !important;
  background-clip: padding-box;

  border: none !important;
  border-radius: 5px !important;
}
.mobile-field{
  background-color: #333333 !important;
  background-clip: padding-box;

  border: none !important;
}

::placeholder {
  color: #666666 !important;
}

.search-btn-movies {
  padding: 6px 31px !important;
  background-color: #333333 !important;
  border: none !important;
  border-radius: 5% !important;
  /* margin-left: 2rem; */
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.page-item {
  margin: 0 2px;
}

.page-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #333333; /* Text color */
  background-color: #333333;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  text-decoration: none;
  border-radius: 0px !important;
  border: none;
  cursor: pointer;
}
.page-item{
  cursor: pointer;
}
.page-btn{
  cursor: pointer;
}
.page-item:hover {
  background-color: #ffffff !important;
}
.page-link:hover {
  background-color: #ffffff;
}
.page-item.active{
  color: #fff;

}
.page-item.active .page-link {
  background-color: #333333 !important; /* Active background color */
  color: #fff;
  border-color: #333333 !important;
  border: none;
  border-radius: 0px !important;
}

.page-item.disabled .page-link {
  background-color: #e9ecef;
  color: #6c757d;
  border-color: #dee2e6;
  border: none;
  border-radius: 0px !important;
}

button.page-link {
  color: #000000;
  border: none;
  border-radius: 0px !important;
}

.page-item.disabled .page-link {
  background-color: #333333;
  color: #000000;
  border-color: #333333;
  border: none;
  border-radius: 0px !important;
}
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin-left: 2rem;
}

.page-link:hover {
  background-color: #333333 !important;
  color: #000000 !important;
}

/* form css start */

.FormContainer {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.Label {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}

.Input {
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.CheckboxLabel {
  margin-bottom: 10px;
  font-size: 12px;
  text-align: left;
}

.Checkbox {
  margin-right: 5px;
}

.Button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.Button:hover {
  background-color: #0056b3;
}

.Button:disabled {
  background-color: #c0c0c0;
  cursor: not-allowed;
}

.title,
.sectionTitle,
.info,
button {
  font-family: "Montserrat", sans-serif;
}

.title {
  font-family: "Montserrat", sans-serif;
  font-size: 67px;
  color: #ffffff;
  font-weight: 700;
}

.sectionTitle {
  font-size: 22px;
  color: #ffffff;
  margin-bottom: 28px;
  text-transform: uppercase;
  font-weight: 700;
}

.info {
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  line-height: 19px;
  margin-top: -10px;
}

.upgrade-btn,
.deactivate-btn,
.pass-btn,
.payment-btn,
.referred-users-btn {
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.upgrade-btn {
  background-color: #28a745; /* Bootstrap success color */
}

.deactivate-btn {
  background-color: #dc3545; /* Bootstrap danger color */
}

.pass-btn {
  background-color: #007bff; /* Bootstrap primary color */
}

.payment-btn {
  background-color: #17a2b8; /* Bootstrap info color */
}

.referred-users-btn {
  background-color: #ffc107; /* Bootstrap warning color */
}

p.btn.deactivate-p.text-white {
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-end;
  /* position: absolute; */
  margin-top: 6rem;
}

.pin-box {
  color: #ff3a5f !important;
}

button.btn.pass-btn {
  background-color: #ededed;
  color: black;
  width: 70%;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  border-radius: 0px;
  height: 26px;
  padding: 0px;
  margin-top: 35px;
}

.border-bottom-a {
  border-bottom: 1px solid #333333;
  margin-bottom: 83px;
}

.card.accounts-card {
  background-color: black;
}

.accounts-card .card-body {
  padding: 0px;
}

.accounts-card .card-footer {
  padding: 0px;
}

.accounts-card .card-footer {
  padding: 0px;
  position: absolute;
  top: 262px;
  width: 100%;
}

button.btn.pass-btn-last-sec {
  background-color: #ededed;
  height: 30px;
  padding: 0px;
  width: 330px;
  border: none;
  border-radius: 0px;
  margin: 6px 20px;
  color: black;
  font-weight: 700;
  font-size: 14px;
  margin-left: 0px;
}

.score-circle {
  /* background-color: black; */
  /* border: 6px solid green; */
  width: 80px;
  height: 80px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  color: green;
  font-size: 28px;
  font-weight: 700;
}

.card.review-card {
  background-color: black;
}

.red {
  background-color: black;
  border: 6px solid #ff3a5f;
  width: 112.62px;
  height: 112.62px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #ff3a5f;
  font-size: 28px;
  font-weight: 700;
}

.green {
  background-color: black;
  border: 6px solid green;
  width: 112.62px;
  height: 112.62px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  color: green;
  font-size: 28px;
  font-weight: 700;
}

.yellow {
  background-color: black;
  border: 6px solid #fff95e;
  width: 112.62px;
  height: 112.62px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff95e;
  font-size: 28px;
  font-weight: 700;
}

.imdb-content {
  padding-left: 57px;
  padding-right: 57px;
}

.rating {
  font-size: 16px;
  font-weight: 400;
  margin-left: -24px;
  color: white;
}

.trueflix-nav {
  padding: 20px 0px;
}

a.navbar-brand {
  margin-left: 1rem;
}
.StreamingServiceItem {
  background-color: black !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  padding-right: 20px;
}

.movies-icons {
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 20px;
}

.StreamingServiceItem {
  background-color: black !important;
  border-bottom: 1px solid #333333;
  border-radius: 0px;
}

.card-yt {
  background-color: black;
}

.card .yt-video iframe {
  width: 100%;
  background-color: black;
  border: none;
}

.ytp-chrome-top.ytp-show-cards-title {
  display: none !important;
}

/* .movies-slider-container {
  height: 128vh!important;
  margin-top: 4rem;
} */

body {
  background-color: black !important;
}
.hero__container {
  height: 100vh;
}

.home-hero-bg > h1 {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 50px;
}

.text-shadow-hero {
  text-shadow: 9px 10px 15px rgba(0, 0, 0, 1.5);
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 50px;
}

/* homepage navbar */

.menu-item {
  margin-left: 50px !important;
}

/* youtube popup css start here  */

.thumbnail-container {
  position: relative;
}

.thumbnail-image {
  width: 100%;
  height: 30vh;
}

.play-btn-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-rnmm7m-MuiPaper-root-MuiDialog-paper {
  /* background-color: #ffff!important; */

  width: calc(100% - 64px);
}

svg.yt-btn {
  width: 70px;
  height: 70px;
}

.custom-dialog {
  /* position: relative; */
  /* background-color: black; */
}

img.img-fluid.platform-icon {
  width: 80px;
}

.custom-dialog-content {
  padding: 0;
  background-color: black;
  overflow: hidden;
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff; /* Close button color */
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Semi-transparent black background for the button */
  border-radius: 50%;
}

.close-btn:hover {
  background: rgba(0, 0, 0, 0.7); /* Darken the background on hover */
}

.close-btn .MuiSvgIcon-root {
  font-size: 24px;
}
.css-rnmm7m-MuiPaper-root-MuiDialog-paper {
  background-color: #00000000 !important;
  width: calc(100% - 64px);
}

.close-btn .MuiSvgIcon-root {
  font-size: 8px;
  position: absolute;
  left: -179px;
  right: 0px;
  top: -8px;
  z-index: 12;
  background-color: black;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  padding: 15px;
}

.thumbnail-image {
  width: 100%;
  /* height: 450px!important; */
}
/* end */

/* .form-control {
  display: block;
  width: 100%;
  padding: 14px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: #3333;
  background-clip: padding-box;
  border: #3333;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */

.form-group.justify-content-flex {
  display: flex;
  background-color: #333;
}

.form-group.justify-content-flex {
  display: flex;
  background-color: #333;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.input-trueflix {
  /* font-weight: 700; */
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  background-color: #333 !important;
  border: none;
  width: 80%;
  color: white;
  fill: none;
  background: transparent;
  border: none;
  height: 64px !important;
  border-radius: 0px;
}
.whatsapp-input {
  /* font-weight: 700; */
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  background-color: #333 !important;
  border: none;
  width: 50%;
  color: white;
  fill: none;
  background: transparent;
  border: none;
  height: 64px !important;
  border-radius: 0px;
  padding-left: 0px !important;
}

.login-input-trueflix {
  /* font-weight: 700; */
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  background-color: #33333300 !important;
  border: none;
  width: 80%;
  color: white !important;
  fill: none;
  background: transparent;
  border: none;
}
.login-input-trueflix::placeholder {
  color: transparent;
  background: transparent;
}
.input-trueflix::placeholder {
  color: inherit; /* Use default text color */
  background: transparent; /* Remove background color */ /* Remove background color */
}

.form-group.justify-content-flex {
  display: flex;
  background-color: #333;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  height: 80.32px;
}

.navbar-toggler {
  border: none !important;
}

p.email {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
p.name {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
p.password {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
p.wanumber {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
p.login-password {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

.col-lg-2.email {
  border-right: 9px solid #1a1a1a;
  width: 247px;
  margin-left: 7rem;
  text-transform: uppercase;
  color: white;
  height: 100%;
  padding-right: 47px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.col-lg-2.name {
  border-right: 9px solid #1a1a1a;
  width: 247px;
  margin-left: 7rem;
  text-transform: uppercase;
  color: white;
  height: 100%;
  padding-right: 47px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.col-lg-2.password {
  border-right: 9px solid #1a1a1a;
  width: 247px;
  margin-left: 7rem;
  text-transform: uppercase;
  color: white;
  height: 100%;
  padding-right: 47px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.col-lg-2.wanumber {
  border-right: 9px solid #1a1a1a;
  width: 247px;
  margin-left: 7rem;
  text-transform: uppercase;
  color: white;
  height: 100%;
  padding-right: 47px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

button.account-submit.sign-up {
  border: none;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
  padding: 26px;
  color: #333333;
  background-color: #333333;
  margin-right: 30px;
}

label.form-check-label.text-white {
  font-size: 22px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  line-height: 25px;
}

h2.mb-5.text-white.signup-heading {
  font-size: 50px;
  line-height: 50px;
}

button.account-submit.sign-up {
  border: none;
  height: 30px;
  padding: 0px;
  font-size: 14px;
  margin: 0;
}

.form-control:focus {
  box-shadow: none !important;
  border: none !important;
  color: white !important;
}

.event-first-sec {
  border-bottom: 1px solid #333333;
  padding-bottom: 70px;
  padding-top: 70px;
}

.main-img {
  width: 100%;
  padding-right: 0px !important;
}

/* stripe payment modal */

.stripe__modal {
  width: 45%;
  height: 50%;
  background-color: #131313;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* padding: 10px; */
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
}
.stripe__modal h4 {
  text-align: center;
  color: white;
  padding: 10px;
  padding-bottom: 20px;
}
.close_icon_container {
  width: 100%;
  height: 10%;
  /* background-color: #fff; */
  /* display: flex; */
  /* justify-content: center; */
}
.close_icon_container p {
  float: right;
  margin-top: 8px;
  margin-right: 15px;
  font-size: 30px;
  cursor: pointer;
  text-transform: uppercase;
  color: white;
  
}
.stripe__button_container {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}


@media (max-width: 426px) {
  .stripe__modal {
    width: 80%;
  }
}
@media (max-width: 326px) {
  .stripe__modal {
    width: 80%;
  }
}

/* Referred Users Modal */
.referred__modal__container {
  width: 70%;
  height: 75%;
  background-color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  display: flex;
}
.modal__title {
  color: #fff;
  font-size: 1rem;
  margin-left: 30px;
}
.referred__modal__container .close__icon__container {
  width: 100%;
  height: 10%;
  background-color: #333;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.close__icon__box {
  width: 45%;
  height: 100%;
  float: right;
  /* background-color: #fff; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.close__icon__box p {
  color: white;
  font-size: 1.5rem;
  padding-right: 40px;
  padding-top: 10px;
  cursor: pointer;
}
.list__container {
  width: 100%;
  height: 90%;
  background-color: #333;
  overflow: scroll;
  overflow-x: hidden;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.referred__card {
  width: 100%;
  height: 60%;
  /* background-color: #fff; */
  /* margin-bottom: 5px; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 2px solid gray;
}
.referred__card .name__container {
  width: 100%;
  height: 30%;
  background-color: #333;
  /* margin-bottom: 2px; */
  display: flex;
  align-items: center;
}
.name__container h2 {
  padding-left: 40px;
}
.name__container h4 {
  padding-left: 40px;
}
.code__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.referral__date {
  padding-right: 200px;
}

/* new css start here  */

.footer-logo.text-right {
  text-align: justify;
}

ul.footer-links {
  list-style: none;
  text-align: end;
  
}

.footer-logo.tex-left {
  text-align: start;
}


.login-input-trueflix div {
}

.movie-poster {
  flex: 0 0 auto;
  width: 100%;
  max-width: 160px !important;
  aspect-ratio: 0.68;
  object-fit: cover;
  cursor: pointer;
  margin-right: 1rem;
}
/* end  */


/* movie details new */
.channels-1{
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 15px;
}
.service{
  width: 90%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #fff; */
}
.service-info{
  width: 80%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  /* background-color: #ef2121; */
}
.service-info h5{
  color: white;
}
.service-info p{
  color: white;
}

.reviews-container{
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: row;
  /* background-color: #acacac; */
  
}
.review-container{
  width: 50%;
  height: 100%;
  /* background-color: #c62f2f; */
}
.review-container h2{
  color: white;
}

.css-hz1bth-MuiDialog-container{
  background-color: #242424a7;
}

/* .css-78trlr-MuiButtonBase-root-MuiIconButton-root{
  display: none;
} */

.review-box{
  width: 100%;
  height: 170px;
  /* background-color: #fff; */
  margin-top: 20px;
}



/* privacy policy pages */
.contentContainer{
  width: 60%;
  /* background-color: #c72424; */
}
.contentContainer h2{
  color: white;
}


.pagination__container{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}