.access-code-1 {
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 50px 60px;
}

@media (max-width: 991px) {
  .access-code-1 {
    max-width: 100%;
    padding: 0 20px;
  }
}

.access-code-2 {
  display: flex;
  width: 100%;
  max-width: 1596px;
  flex-direction: column;
  align-items: center;
  margin: 12px 0 88px;
}

@media (max-width: 991px) {
  .access-code-2 {
    max-width: 100%;
    margin-bottom: 40px;
  }
}

.subscription-info {
  background-color: black;
}

.image {
  width: 54px;
  height: 141px;
  object-fit: cover;
}

.image-gallery {
  display: flex;
  margin-top: 20px;
  gap: 40px;
  justify-content: center;
  align-items: center;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.button {
  background-color: #ededed;
  padding: 10px 60px;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #cbcbcb;
}

/* Media query for 1200px screens */
@media (max-width: 1200px) {
  .access-code-1,
  .access-code-2,
  .subscription-info,
  .image-gallery,
  .action-buttons {
    padding: 40px; /* Adjust padding for medium screens */
    margin-bottom: 20px; /* Adjust margin for medium screens */
  }

  .button {
    padding: 10px 40px; /* Adjust button padding for medium screens */
  }
}

/* Media query for 992px screens */
@media (max-width: 992px) {
  .access-code-1,
  .access-code-2,
  .subscription-info,
  .image-gallery,
  .action-buttons {
    padding: 30px; /* Adjust padding for small screens */
    margin-bottom: 15px; /* Adjust margin for small screens */
  }

  .button {
    padding: 10px 30px; /* Adjust button padding for small screens */
  }
}

/* Media query for 768px screens */
@media (max-width: 768px) {
  .access-code-1,
  .access-code-2,
  .subscription-info,
  .image-gallery,
  .action-buttons {
    padding: 20px; /* Adjust padding for extra small screens */
    margin-bottom: 10px; /* Adjust margin for extra small screens */
  }

  .button {
    padding: 10px 20px; /* Adjust button padding for extra small screens */
  }
}

/* Media query for 600px screens */
@media (max-width: 600px) {
  .image-gallery {
    flex-direction: column; /* Stack images vertically for smaller screens */
    align-items: flex-start; /* Align images to the start for smaller screens */
  }

  .action-buttons {
    flex-direction: column; /* Stack buttons vertically for smaller screens */
    align-items: center; /* Align buttons to the center for smaller screens */
  }

  .button {
    width: 100%; /* Make button full-width for smaller screens */
  }
}

/* Media query for 480px screens */
@media (max-width: 480px) {
  .access-code-1,
  .access-code-2,
  .subscription-info,
  .image-gallery,
  .action-buttons {
    padding: 10px; /* Adjust padding for the smallest screens */
    margin-bottom: 5px; /* Adjust margin for the smallest screens */
  }

  .image-gallery {
    gap: 20px; /* Adjust gap for the smallest screens */
  }

  .button {
    padding: 10px 15px; /* Adjust button padding for the smallest screens */
  }
}
