.title__container{
    /* width: 90%;
    height: 100%; */
    /* background-color: #fff; */
    display: flex;
    justify-content: center;
}

.text__container{
    width: 45%;
    height: 100%;
    margin-top: 20px;
    /* background-color: #88a93d; */
}
.count__container{
    width: 50%;
    height: 100%;
    /* background-color: #3d6ca9; */
    /* margin-left: 10px; */
    margin-top: 20px;
    float: right;
}
.text__container h2{
    color: white;
}
.count__container__inner{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    float: right;
    /* background-color: #ed1464; */
    /* text-align: center; */
}
.count__container h2{
    align-self: flex-end;
    margin-right: 40px;
    color: white;
}
.count__container span{
    font-size: 12px;
    align-self: flex-end;
    text-align: center;
    color: white;
}

.user__card{
   
    height: 250px;
    /* background-color: #fff; */
    margin-top: 2px;
    display: flex;
}

.serial__number{
    width: 20%;
    height: 100%;
    /* background-color: #4f1919; */
    text-align: center;
}
.serial__number h1{
    color: gray;
    font-size: 50px;
}
.user__info{
    width: 80%;
    height: 100%;
    /* background-color: #239c76; */
}
.user__info span{
    font-size: 14px;
    color: gray;
}
.user__info h6{
    color: white;
}
