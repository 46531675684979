/* Default styling for larger screens */
.access-code-1,
.app-container {
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* padding: 50px 60px; */
}

.access-code-2 {
  display: flex;
  width: 100%;
  max-width: 1596px;
  flex-direction: column;
  align-items: center;
  margin: 12px 0 238px;
}

.territory-div {
  background-color: black;
}

.confirmation-section {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background-color: #000;
  color: #ffff;
  padding: 50px 60px; /* Adjust padding for larger screens */
}

.question {
  width: 60%;
  text-align: center;
  line-height: 75px;
  font-family: Montserrat, sans-serif;
  font-size: 62px;
  font-weight: 700;
}

.description {
  font-family: Montserrat, sans-serif;
  font-size: 23px;
  font-weight: 400;
  margin: -20px 0 60px 0;
}

.response-buttons button {
  margin: 10px;
  font-family: Montserrat, sans-serif;
  background-color: #ededed;
  border: none;
  font-size: 16px;
  font-weight: 700;
  padding: 12px 60px;
  cursor: pointer;
}




/* Media query for 1200px screens */
@media (max-width: 1200px) {
  .access-code-1,
  .app-container,
  .confirmation-section {
    padding: 40px 40px; /* Adjust padding for medium screens */
  }

  .question {
    font-size: 48px; /* Adjust font size for medium screens */
  }
}

/* Media query for 992px screens */
@media (max-width: 992px) {
  .access-code-1,
  .app-container,
  .confirmation-section {
    padding: 30px 30px; /* Adjust padding for small screens */
  }

  .question {
    font-size: 40px; /* Adjust font size for small screens */
  }
}

/* Media query for 768px screens */
@media (max-width: 768px) {
  .access-code-1,
  .app-container,
  .confirmation-section {
    padding: 20px 20px; /* Adjust padding for extra small screens */
  }

  .question {
    font-size: 32px; /* Adjust font size for extra small screens */
  }
}

/* Media query for 600px screens */
@media (max-width: 600px) {
  .question {
    width: 80%; /* Adjust width for smaller screens */
    font-size: 28px; /* Adjust font size for smaller screens */
  }
}

/* Media query for 480px screens */
@media (max-width: 480px) {
  .access-code-1,
  .app-container,
  .confirmation-section {
    padding: 10px 10px; /* Adjust padding for the smallest screens */
  }

  .question {
    width: 100%; /* Adjust width for the smallest screens */
    font-size: 24px; /* Adjust font size for the smallest screens */
  }

  .description {
    margin: -20px 0 30px 0; /* Adjust margin for the smallest screens */
  }

  .response-buttons button {
    padding: 10px 40px; /* Adjust padding for the smallest screens */
  }
}
