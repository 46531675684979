.hero__wrapper__unpaid {
  width: 100%;
  height: 80vh;
  background-image: url("../../public/images/unpaid-black.png"); /* Adjust the path as necessary */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: #000;
  position: relative;
}
.hero__wrapper-blur::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../public/images/unpaid.jpeg"); /* Adjust the path as necessary */
  background-size: cover;
  background-position: center;
  filter: blur(5px);
  /* z-index: -2; */
}
.hero-title {
  font-size: 46px;
  font-weight: 700;
  color:white;
  text-shadow: 7px 7px 11px rgba(0, 0, 0, 1.5);
}
.hero-info-container {
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 400px;
  margin-top: 150px;
  z-index: 222;
}
.hero-text-1{
    color: white;
    font-size: 16px;
    margin-top: 16px;
    text-align: center;
}
.hero-text-2{
    color: white;
    margin-bottom: 10px;
    font-size: 16px;
}
.btn-subtext{
    color: white;
    margin-top: 16px;
    text-align: center;
}
/* @media only screen and (min-width: 1200) and (max-width: 1440px){
    .hero-info-container{
        margin-top: 0px;
    }
    .access-btn-container{
        height: 200px;
    }
}
@media (max-width: 700px) {
    .access-btn-container{
        height: 200px !important;
        border-top-left-radius: 110% 300px;
  border-top-right-radius: 110% 300px;
    }
} */
@media only screen and (min-width: 1920px) and (max-width: 1920px)  {
    .hero-info-container{
        margin-top: 200px !important;
    }
}

.border-rounded-section{
        position: absolute;
        z-index: 55555;
        height: 450px;
        width: 100%;
        /* border-bottom-left-radius: 77px; */
        left: 0;
        margin-top: -250px;
        /* border-top-left-radius: 110% 300px!important;
        border-top-right-radius: 110% 300px; */
        /* background-color: currentcolor; */
        z-index: 99;
    
}


.access-btn-container {
  width: 100%;
  height: 400px;
  align-self: flex-end;
  /* background-color: #000; */
  position: absolute;
  bottom: -35%;
  left: 0;
  border-top-left-radius: 110% 300px; /* Adjust the value to control the roundness */
  border-top-right-radius: 110% 300px;
}
.container {
  position: relative;
  z-index: 2; /* Ensure the container content is above the blurred background */
}

.logo-name {
  text-shadow: 2px 5px 8px rgba(0, 0, 0, 1.5);
  margin-top: 20px;
  margin-left: 30px;
}

.home-nav-link {
  font-weight: 600 !important;
  font-size: 15px !important;
  margin-top: 15px;
}
.acc-link-1 {
  margin-right: 60px !important;
}
.acc-ul {
  margin-right: 65px !important;
}

.hero-title-wrapper {
  width: 100%;
  height: 324px;
  /* background-color: #fff; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.box {
  width: 40%;
  height: 100%;
}
.watch-text {
  font-family: "Montserrat";
  color: white !important;
  font-weight: 520;
  font-size: 18px;
  /* line-height: 30px; */
}
/* .breaking-bad-h1 {
  font-family: "Montserrat";
  font-weight: 680;
  text-shadow: 7px 7px 11px rgba(0, 0, 0, 1.5);
  color: white;
  font-size: 100px;
  margin-bottom: 30px;
  line-height: 120px;
} */
.hero-description-text {
  width: 98%;
  font-family: "Montserrat";
  color: white !important;
  /* font-weight: 600; */
  font-size: 20px;
  line-height: 28px;
}

.hero__text__container {
  width: 50%;
  height: 70%;
  background-color: transparent;
  margin-left: 20%;
  margin-top: 10%;
}
.hero__text__container h4,
.hero__text__container h1 {
  background-color: transparent;
}
.hero__text__container h4 {
  font-family: "Montserrat";
  font-size: 25px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}
.hero__text__container h1 {
  font-family: "Montserrat";
  font-size: 115px;
  font-weight: 700;
  line-height: 141px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.para__container {
  background-color: transparent;
  width: 100%;
  height: 40%;
  margin-top: 0px;
}
.para__container p {
  background-color: transparent;
  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 400;
  /* line-height: 31px; */
  /* letter-spacing: 0em; */
  text-align: left;
  color: white;
}

.home-hero-bg {
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 8rem;
  padding-right: 7rem;
  min-height: calc(90vh - 200px);
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.navbar {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  /* background-color: transparent; */
  color: black;
}

.brand-title {
  font-size: 1.5rem;
  margin: 0.5rem;
}

.navbar-links {
  height: 100%;
}

.navbar-links ul {
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  list-style: none;
}

.navbar-links li a {
  display: block;
  text-decoration: none;
  color: black;
  padding: 1rem;
}

/* .navbar-links li:hover {
      background-color: #555;
  } */

.toggle-button {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}

/* 
  @media (max-width: 800px) {
      .navbar {
          flex-direction: column;
          align-items: flex-start;
      }
  
      .toggle-button {
          display: flex;
      }
  
      .navbar-links {
          display: none;
          width: 100%;
      }
  
      .navbar-links ul {
          width: 100%;
          flex-direction: column;
      }
  
      .navbar-links ul li {
          text-align: center;
      }
  
      .navbar-links ul li a {
          padding: .5rem 1rem;
      }
  
      .navbar-links.active {
          display: flex;
      }
  }
  
  /* Responsive adjustments */
@media (max-width: 1500px) {
  .hero-description-text {
    width: 80%;
    font-size: 25px;
    line-height: 25px;
  }
}
@media (max-width: 600px) {
  .hero__wrapper {
    height: 550px;
    background-image: url(../../public/images/mobile_bg-img.jpg);
    background-size: cover;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
  }
  .logo-name {
    margin-left: -8px;
    margin-top: 0px;
    font-size: 30px;
  }
  .watch-text {
    font-size: 25px;
  }
  .hero-info {
    margin-top: 50px;
    margin-left: 11px;
  }
  .breaking-bad-h1 {
    font-size: 30px;
  }
  .hero-description-text {
    font-size: 20px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 350px) {
  .breaking-bad-h1 {
    font-size: 20px;
  }
}

/* @media only screen and (max-width: 576px) {
    .hero__wrapper {
      height: 80vh;
    }
  } */
@media only screen and (max-width: 768px) {
  .breaking-bad-h1 {
    font-size: 65px;
    line-height: 65px;
  }
}
@media only screen and (max-width: 1440px) {
  .home-hero-bg {
    padding-top: 10rem !important;
    padding-bottom: 0rem;
    padding-right: 6rem !important;
  }

  .home-hero-bg > .text-shadow-hero {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 100px;
  }
}

@media only screen and (max-width: 768px) {
  .home-hero-bg {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 1.5rem;
    padding-bottom: 0rem;
    padding-right: 0rem;
  }
  .home-hero-bg p {
    font-size: 12px;
  }
  .home-hero-bg h1 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 475px) {
  .home-hero-bg {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 0rem;
  }
  .img-fluid {
    max-width: 100%;
    height: auto;
    padding-right: 0px !important;
  }

  .home-hero-bg > h1 {
    font-size: 3rem !important;
  }
}
@media only screen and (max-width: 375px) {
  .home-hero-bg {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 0rem;
  }
  .home-hero-bg > h1 {
    font-size: 3rem !important;
  }
  .home-hero-bg > h1 > svg {
    width: 80px;
    height: 80px;
  }
}

@media only screen and (max-width: 320px) {
  .home-hero-bg {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 0rem;
  }
  .home-hero-bg > h1 {
    font-size: 1.5rem !important;
  }
  .home-hero-bg > h1 > svg {
    width: 50px;
    height: 50px;
  }
}

/* .jumbotron {
    color: white;
    background-image: url(../../../public/images/hero-banner.jpg);  
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    min-height: calc(100vh - 200px);
  } */

/* home page modal */
.hero-modal {
  width: 40%;
  height: 40%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #232222;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.modal-logo-container {
  width: 100%;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-logo-container p {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 18px;
  cursor: pointer;
}

.modal-text-container {
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-text-container p {
  color: white;
}
.modal-button-container {
  width: 100%;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.hero-modal .modal-logo {
  padding: 10px;
  color: white;
  font-size: 22px;
}

@media (max-width: 768px) {
  .hero-modal {
    width: 80%; /* Adjust as needed */
    height: auto;
    padding: 20px;
  }
  .hero-modal h4 {
    font-size: 16px;
  }
  .deactivate-sub-btn {
    height: 35px;
    width: 60%;
  }
}
