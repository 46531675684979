/* Styles for the slide-in menu */
@media (max-width: 991px) {
  .navbar-collapse {
    position: fixed;
    top: 0;
    right: -250px; /* Initially hidden */
    width: 250px;
    height: 100%;
    background-color: #333;
    transition: right 0.3s ease;
  }

  .navbar-collapse.show {
    right: 0; /* Slide in when 'show' class is added */
  }

  .navbar-nav {
    flex-direction: column;
    padding-top: 20px;
  }

  .nav-item {
    text-align: center;
  }

  .nav-link {
    padding: 10px;
    color: #fff;
  }
}

.navbar-toggler{

z-index: 50;
}

.navbar-toggler .navbar-toggler-icon {
  background-image: url("../../../public/images/menu.svg"); /* Your hamburger icon */
  width: 26px;
  height: 26px;
  background-size: contain;
  transition: all 0.3s ease;
  background-color: transparent !important;
  outline: none !important;
  margin-top: -18px;
}



.navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("../../../public/images/close.svg");  /* Your close (x) icon */
  width: 33px;
  height: 33px;
  background-color: transparent;
  outline: none !important;
}

.navbar-collapse.show {
  right: 0;
  background-color: black;
}
:focus-visible {
outline: none !important;
}
/* button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
cursor: none  !important;
} */
.navbar-toggler:focus {
text-decoration: none;
outline: none !important;
box-shadow: none !important;

/* outline: 0; */
/* box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); */
}