.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.otp-input {
  width: 85px;
  height: 85px;
  margin: 0 6px;
  background-color: #333333;
  border: none;
  border-radius: 0;
  font-size: 30px;
  text-align: center;
  color: #ffff;
  text-transform: uppercase;
}

.account-submit {
  background-color: rgba(237, 237, 237, 1);
  margin-top: 40px;
  width: 300px;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  color: #000;
  white-space: nowrap;
  padding: 12px 30px;
  font: 700 17px Montserrat, -apple-system, Roboto, Helvetica, sans-serif;
  border: none;
}
@media (max-width: 1200px) {
  .otp-input {
    width: 72px;
    height: 72px;
    font-size: 24px;
  }

  .account-submit {
    width: 150px;
    padding: 12px 20px;
  }
}
@media (max-width: 992px) {
  .otp-input {
    width: 72px;
    height: 72px;
    font-size: 24px;
  }

  .account-submit {
    width: 150px;
    padding: 12px 20px;
  }
}
@media (max-width: 767px) {
  .otp-input {
    width: 52px;
    height: 52px;
    font-size: 24px;
  }

  .account-submit {
    width: 150px;
    padding: 12px 20px;
  }
}

/* Additional media query for smaller screens, such as mobile phones */
@media (max-width: 480px) {
  .otp-input {
    width: 30px;
    height: 30px;
    font-size: 18px;
  }

  .account-submit {
    width: 135px;
    padding: 10px 15px;
    margin-top: 30px;
  }
}
